import { cx } from '@emotion/css';
import type { Platform as PlatformHint } from '@snapchat/client-hints';
import type { DropdownFieldOption } from '@snapchat/mw-contentful-schema';
import type { SubmitProps } from '@snapchat/snap-design-system-marketing';
import {
  Alignment,
  BrowserFeaturesContext,
  Button,
  Select,
} from '@snapchat/snap-design-system-marketing';
import type { BaseSyntheticEvent, FC } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';

import { FieldByType } from '../../../../components/Form/FieldByType';
import { logError, logWarning } from '../../../../helpers/logging';
import {
  formFieldsCss,
  formRowCss,
  formRowPlatformCss,
  gapLargeCss,
  rowAlignCenterCss,
  rowAlignRightCss,
  selectHeightLargeCss,
} from './ArDownloadFormBlock.styles';
import type { ArDownloadFormFieldsProps } from './types';
import { ArDownloadFormStyle, Platform } from './types';

const getInitialValue = (
  platform: PlatformHint,
  dropdownOptions: DropdownFieldOption[],
  architecture: string | undefined
) => {
  if (platform === 'macOS') {
    if (
      architecture?.toLowerCase() === 'arm' &&
      dropdownOptions.some(({ value }) => value === Platform.MAC_OS_ARM)
    ) {
      return Platform.MAC_OS_ARM;
    }

    return Platform.MAC_OS;
  }

  return Platform.WINDOWS;
};

// TODO - avoid component factory approach
// Consider other approaches, e.g custom hooks
// Currently using a factory to fix under the closure parameters besides `SubmitProps`
export const makeArDownloadFormFields: (props: ArDownloadFormFieldsProps) => FC<SubmitProps> = ({
  version,
  platform,
  eula,
  submitText,
  submitSuccessText,
  alignment,
  formStyle,
}) => {
  const rowStylesMap = {
    [rowAlignCenterCss]: alignment === Alignment.Center,
    [rowAlignRightCss]: alignment === Alignment.Right,
    [selectHeightLargeCss]: formStyle === ArDownloadFormStyle.Block,
  };

  const fieldsClass = cx(formFieldsCss, {
    [gapLargeCss]: formStyle === ArDownloadFormStyle.Block,
  });

  const items = platform.optionsCollection?.items ?? [];

  const allPlatformValues = items.map(({ value }) => value!);

  const ArFormFields: FC<SubmitProps> = ({ handleSubmit, disabled, loading, submitSuccess }) => {
    const browserFeatures = useContext(BrowserFeaturesContext);
    const [architecture, setArchitecture] = useState<string>();

    useEffect(() => {
      const fetchArchitecture = async () => {
        const hints = await browserFeatures.getHighEntropyHintsAsync({ hints: ['architecture'] });
        setArchitecture(hints.architecture);
      };

      fetchArchitecture().catch(error => {
        logError({ component: 'ARDownloadForm', error });
      });
    }, [browserFeatures]);

    const submitDownloadForm = useCallback(
      (event: BaseSyntheticEvent | MouseEvent | KeyboardEvent | undefined) => {
        event?.preventDefault();
        const form = event?.target.closest('form');

        if (form) {
          const data = new FormData(form);
          const entries: [string, FormDataEntryValue][] = [];

          data.forEach((value, key) => {
            entries.push([key, value]);
          });
          const formFields = Object.fromEntries(entries);
          const requiredFields = ['eula', 'platform', 'version'];
          const missingFields = [];

          for (const field of requiredFields) {
            if (!(field in formFields) || formFields[field] === undefined) {
              missingFields.push(field);
            }
          }

          if (missingFields.length > 0) {
            logWarning({
              component: 'ARDownloadForm',
              message: `Missing fields in LS download form: ${missingFields.join(', ')}`,
            });
          }
        }
        handleSubmit();
      },
      []
    );

    return (
      <div className={fieldsClass}>
        <div className={cx(formRowCss, rowStylesMap)}>
          <FieldByType contentfulInputField={version} submitText={submitText} />
          <div>
            <FieldByType contentfulInputField={eula} submitText={submitText} />
          </div>
        </div>

        <div className={cx(formRowCss, formRowPlatformCss, rowStylesMap)}>
          <Select
            name={platform.name!}
            key={platform.sys.id}
            initialValue={getInitialValue(
              browserFeatures.getLowEntropyHints().platform,
              items,
              architecture
            )}
            allValues={allPlatformValues}
            shouldResetToInitial={platform.shouldResetToInitial}
            required={platform.required}
          >
            {items.map(({ key, value }) => (
              <option key={key!} value={value || key}>
                {key}
              </option>
            ))}
          </Select>

          <Button
            type="Primary"
            size={formStyle === ArDownloadFormStyle.Block ? 'Regular' : 'Compact'}
            onClick={submitDownloadForm}
            disabled={disabled}
            loading={loading}
          >
            {submitSuccess ? submitSuccessText ?? submitText : submitText}
          </Button>
        </div>
      </div>
    );
  };

  return ArFormFields;
};
